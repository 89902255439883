<template>
<div>

  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Edit Schedule Task">

      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">

        <b-row>
          <b-col md="6" >
            
            <b-form-group
              label="Project Site"
              class="required"

            >
              <b-form-select v-model="form.site" @change="resetData()">

                <b-form-select-option value="" disabled>Select</b-form-select-option>
                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name}}</b-form-select-option>
              </b-form-select>
                
            </b-form-group>
          </b-col>

          <b-col md="6" >
            
            <b-form-group
              label="Location"
              class="required"

            >
              <v-select
                v-model="form.locations"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="name"
                :options="locations"
                placeholder="Select"
                :close-on-select=false
                :clear-on-select=false
                class="multiselect_muliple_options"
              />

              <p class="text-warning" v-if="form.site != ''" @click="openModel()">
                <feather-icon icon="PlusCircleIcon" />
                  <span class="align-middle ml-50" style="cursor: pointer">Add Location</span>
              </p>
                
            </b-form-group>
          </b-col>

        </b-row>
            
        <b-row>
          <b-col md="12">

                <b-form-group
                  label="Frequency"
                  class="required mb-1"
                >


                  <b-form-select v-model="form.frequency" @change="updateFrequencies">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="frequency.name" v-for="frequency in frequencies" :key="frequency._id">{{frequency.name}}</b-form-select-option>
                  
                  </b-form-select>
                </b-form-group>
          </b-col>  
        </b-row>

        <b-row>
          
          <b-col md="12">

            

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Task Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="2 to 100 characters"
                    v-model="form.title"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row class="mb-2 margin_bottom_zero_mobile">
              <b-col md="12">
                <b-form-group
                  label="Description"
                  class="required"
                >
                  <b-form-textarea
                    placeholder="upto 500 characters"
                    rows="3"
                    v-model="form.description"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>

            <b-row v-if="form.show_group == 'yes'">
              <b-col md="12">
                <b-form-group
                  label="Group"
                  class="required"
                >
                 
                  <!-- <vue-simple-suggest
                    v-model="form.group"
                    :list="group_array"
                    :filter-by-query="true"
                    placeholder="Max 50 characters"
                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                    >
                    
                  </vue-simple-suggest> -->

                  <v-select
                    v-model="form.group"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="group_array"
                    multiple
                    placeholder="Select"
                    :close-on-select=false
                    :clear-on-select=false
                    class="multiselect_muliple_options"
                  />

                  <p class="text-warning" @click="openGroupModel()">
                    <feather-icon icon="PlusCircleIcon" />
                      <span class="align-middle ml-50" style="cursor: pointer">Add Group</span>
                  </p>


                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <!-- <b-col md="6" >
                
                <b-form-group
                  label="Date"
                  class="required"

                >
                  <date-range-picker ref="picker" :minDate="minDate" :opens="center" :locale-data="locale" :auto-apply="auto" v-model="form.dateRange" :ranges='range' :timePicker="timePicker":timePicker24Hour="timePicker24Hour" class="w-100">
                      <div slot="input" slot-scope="picker">
                        <span v-if="picker.startDate != '' && picker.startDate != null">
                          {{picker.startDate | dateRangePicker }} - {{ picker.endDate | dateRangePicker }} 
                        </span>
                        <span v-else>
                          {{'Select Date'}}
                        </span>
                          
                      </div>
                  </date-range-picker>
                    
                </b-form-group>
              </b-col> -->

              <b-col md="4" >

                <b-form-group
                  label="Start Date"
                  class="required"

                > 
                  <!-- <datepicker v-model="form.dateRange.startDate" :use-utc="clearButton" :clear-button="clearButton" :format="customFormatter" :highlighted="highlighted"></datepicker> -->
                  <b-form-datepicker
                  
                  v-model="form.dateRange.startDate"
                  
                  :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                  locale="en"
                />

                    
                </b-form-group>
              </b-col>

              <b-col md="4" >
                <b-form-group
                    label="End Date"
                    class="required"

                  >
                    <!-- <datepicker v-model="form.dateRange.endDate" :use-utc="clearButton" :clear-button= "clearButton" :format="customFormatter" :highlighted="highlighted"></datepicker> -->
                    <b-form-datepicker
                  
                    v-model="form.dateRange.endDate"
                    
                    :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                    locale="en"
                  />

                      
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                  label="NFC Code"
                  class=""
                >
                  <b-form-input
                    placeholder="2 to 100 characters"
                    v-model="form.nfc_code"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row class="mt-1 mb-1 margin_top_zero_mobile">

              <b-col md="6">
                
                <b-form-group
                  label=""
                  class="margin_bottom_zero_mobile"
                >
                  <b-form-checkbox
                    v-model="form.planned_time_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.plannedStartTime = '00:00'"
                  >
                    Planned Start Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>

              
          
            </b-row>

            <b-row v-if="form.planned_time_modify == 'yes'">

                <b-col md="6">

                  <b-form-group
                      label="Planned Start Time"
                      class="margin_bottom_zero_mobile"

                    >
                      <flat-pickr
                        v-model="form.plannedStartTime"
                        class="form-control"
                        :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                      />
                        
                  </b-form-group>

                  
                </b-col>

              </b-row>

            <b-row class="mt-1">

              <b-col md="6">
                
                <b-form-group
                  label=""
                >
                  <b-form-checkbox
                    v-model="form.start_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.startTime = '00:00'"
                  >
                    Start Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>

              <b-col md="6">
                
                <b-form-group
                  label=""
                >
                  <b-form-checkbox
                    v-model="form.end_modify"
                    value="yes"
                    unchecked-value="no"
                    class="custom-control-warning"
                    @change="form.endTime = '00:00'"
                  >
                    End Time
                  </b-form-checkbox>
                  
                </b-form-group>
              </b-col>
          
            </b-row>

            <b-row>

              <b-col md="6" v-if="form.start_modify == 'yes'">

                <b-form-group
                    label="Start Time"
                    class=""

                  >
                    <flat-pickr
                      v-model="form.startTime"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                    />
                      
                </b-form-group>

                
              </b-col>
              <b-col md="6" v-if="form.end_modify == 'yes'">

                <b-form-group
                    label="End Time"
                    class=""

                  >
                    <flat-pickr
                      v-model="form.endTime"
                      class="form-control"
                      :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
                    />
                      
                </b-form-group>

                
              </b-col>
            </b-row>
            <b-row>

              <b-col md="12" >
                
                <b-form-group
                  label="Shift"
                  class="required"

                >
                  <b-form-select v-model="form.shift">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="shift" v-for="shift in shift_array" :key="shift">{{shift}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

            </b-row>

            

            <b-row v-if="form.frequency == 'Daily'">
              <b-col md="6" >
                
                <b-form-group
                  label="Training Content"
                  class=""

                >
                  <b-form-select v-model="form.training">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>

                  </b-form-select>
                    
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="form.frequency == 'Custom'">
              
              
              <b-col md="6" >
                
                <b-form-group
                  label="Time Interval"
                  class="required"

                >
                  <b-form-select v-model="form.interval" @change="updateCustomDay()">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="i" v-for="i in 7" v-if="i > 1" :key="i">{{i}}</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6" >
                
                <b-form-group
                  label="Week/Month"
                  class="required"

                >
                  <b-form-select v-model="form.week_or_month" @change="updateCustomDay()">
                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option value="week">Week</b-form-select-option>
                    <b-form-select-option value="month">Month</b-form-select-option>
                  </b-form-select>
                    
                </b-form-group>
              </b-col>
            </b-row>



            <b-row v-if="form.frequency != 'Daily' && form.frequency != ''">

              <b-col md="6" v-if="form.frequency == 'Monthly' || form.frequency == 'Once in two months' || form.frequency == 'Quarterly' || form.frequency == 'Half-Yearly' || form.frequency == 'Yearly' || form.frequency == 'Yearly' || (form.frequency == 'Custom' && form.week_or_month == 'month')">
                
                <b-form-group
                  label="Duration (in Days)"
                  class="required"
                  
                >
                  
                  <v-select
                    v-model="form.duration"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    :options="mno"
                    @input="resetDuration"
                    :clearable="false"
                    class="multiselect_muliple_options"
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="form.frequency == 'Weekly' || form.frequency == 'Fortnightly' || (form.frequency == 'Custom' && form.week_or_month == 'week')">
                
                <b-form-group
                  label="Duration (in Days)"
                  class="required"

                >
                  <!-- <b-form-select v-model="form.duration">

                    <b-form-select-option value="">Select</b-form-select-option>
                    <b-form-select-option :value="day" v-for="day in wno" :key="day">{{day}}</b-form-select-option>
                    
                  </b-form-select> -->

                  <v-select
                    v-model="form.duration"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    :options="wno"
                    @input="resetDuration"
                    :clearable="false"
                    class="multiselect_muliple_options"
                  />
                    
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="form.frequency == 'Fortnightly' ||form.frequency == 'Monthly'||form.frequency == 'Once in two months' || form.frequency == 'Quarterly' || form.frequency == 'Half-Yearly' || form.frequency == 'Yearly' || form.frequency == 'Custom'">
                <b-form-group
                  label="Start Month"
                  class=""

                >
                  
                  <v-select
                    v-model="form.month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select"
                    :options="months"
                    :clearable="false"
                    class="multiselect_muliple_options"
                  />
                    
                </b-form-group>

              </b-col>

            </b-row>

            <b-row v-if="form.frequency == 'Weekly' || form.frequency == 'Fortnightly' || (form.frequency == 'Custom' && form.week_or_month == 'week')">

              <b-col md="6" v-if="form.frequency == 'Fortnightly'">
                
                <b-form-group
                  label="Week of Month"
                  class="required"

                >
                  <b-form-select v-model="form.week_of_month">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="day" v-for="day in 5" :key="day">{{day}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>
              
              <b-col md="6" >
                
                <b-form-group
                  label="Start day of Week"
                  class="required"

                >
                  <b-form-select v-model="form.start_day_of_week">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6" >
                
                <b-form-group
                  label="Training Content"
                  class=""

                >
                  <b-form-select v-model="form.training">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>

                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              

            
            </b-row>



            <b-row v-if="form.frequency == 'Monthly' || form.frequency == 'Once in two months' || form.frequency == 'Quarterly' || form.frequency == 'Half-Yearly' || form.frequency == 'Yearly' || (form.frequency == 'Custom' && form.week_or_month == 'month')">

              <b-col md="6" v-if="form.duration != 'Full month'">
                
                <b-form-group
                  label="Week of Month"
                  class="required"

                >
                  <b-form-select v-model="form.week_of_month">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="day" v-for="day in 5" :key="day">{{day}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="form.duration != 'Full month'">
                
                <b-form-group
                  label="Day of Week"
                  class="required"

                >
                  <b-form-select v-model="form.day_of_week">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="day.name" v-for="day in weekdays" :key="day._id">{{day.name}}</b-form-select-option>
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

              <b-col md="6" >
                
                <b-form-group
                  label="Training Content"
                  class=""

                >
                  <b-form-select v-model="form.training">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>

                  </b-form-select>
                    
                </b-form-group>
              </b-col>
              

            
            </b-row>

            
            
            
            <b-row>
              <b-col class="daily_report_sign">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'periodic-tasks' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  <b-modal
        id="add-location"
        ref="add-location"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Add Location"
        @show="resetModal"
        @hidden="resetModal"
        @ok="addLocation"
      >

    <b-alert
    v-model="popshowDismissibleAlert"
    variant="danger"
    dismissible
    class="mb-1"
  >
    <div class="alert-body">
      {{error_message}}
    </div>
  </b-alert>

    <b-form>
      <b-form-group>
        <label for="email">Location Name:</label>
        <!-- <b-form-input placeholder="" v-model="location_name" /> -->
        <vue-simple-suggest
              v-model="location_name"
              :list="location_list"
              :filter-by-query="true"
              placeholder="3 to 100 characters"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
          >
          </vue-simple-suggest>
      </b-form-group>
    </b-form>
  </b-modal>

  <b-modal
    id="add-group"
    ref="add-group"
    cancel-variant="outline-secondary"
    ok-variant="outline-warning"
    ok-title="Submit"
    cancel-title="Close"
    centered
    title="Add Group"
    @show="resetGroup"
    @hidden="resetGroup"
    @ok="addGroup"
  >

    <b-alert
    v-model="popshowDismissibleAlert"
    variant="danger"
    dismissible
    class="mb-1"
  >
    <div class="alert-body">
      {{error_message}}
    </div>
  </b-alert>

    <b-form>
      <b-form-group>
        <label for="email">Group Name:</label>
        <b-form-input
          
          placeholder=""
          v-model="group_name"
          
        />
      </b-form-group>
    </b-form>
  </b-modal>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormTextarea,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { GET_API, POST_API } from "../../../store/actions.type";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import moment from "moment-timezone";
import vSelect from 'vue-select';
import DateRangePicker from 'vue2-daterange-picker';
//you need to import the CSS manually (in case you want to override it)
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    DateRangePicker,
    BFormTextarea,
    vSelect,
    Datepicker,
    VueSimpleSuggest,
    BFormDatepicker,
    flatPickr,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      clearButton:true,
      error_message:null,
      showDismissibleAlert:false,
      popshowDismissibleAlert:false,
      wno:[],
      mno:[],
      form :{
        id:'',
        frequency:'',
        title:'',
        site:'',
        locations:[],
        //supervisor:'',
        training:'',
        shift:'',
        description:'',
        dateRange: {
            startDate: new Date(),
            endDate: new Date()
        },
        start_day_of_week:'',
        duration:'',
        week_of_month:'',
        day_of_week:'',
        month:'',
        interval:'',
        week_or_month:'',
        group:[],
        show_group:'no',
        startTime:'00:00',
        endTime:'00:00',
        start_modify:'no',
        end_modify:'no',
        plannedStartTime:'00:00',
        planned_time_modify:'no',
        nfc_code:'',
      },
      frequencies:[],
      group_name:'',
      group_array:[],
      shift_array:[],

      start_date:'',
      end_date:'',
      
      location_list: [],

      // range: false,
      // date: moment().tz('Asia/Singapore').subtract(1, 'days').format('YYYY-MM-DD'),
      // format: 'YYYY-MM-DD',
      // auto: true,
      // center: 'center',
      // minDate: null,
      // locale: {
      //     direction: 'ltr', //direction of text
      //     format: 'DD-MMM-YYYY', //fomart of the dates displayed
      //     separator: ' - ', //separator between the two ranges
      //     applyLabel: 'Apply',
      //     cancelLabel: 'Cancel',
      //     /*weekLabel: 'W',
      //     customRangeLabel: 'Custom Range',*/
      //     daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
      //     monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
      //     firstDay: 1, //ISO first day of week - see moment documenations for details
      //     sameDateFormat: {
      //         from: "DD MM YYYY, hh:mm",
      //         to: "hh:mm",
      //     },
      // },
      // timePicker24Hour: false,
      // timePicker: false,
      // dateInput: {
      //   placeholder: "Select Date"
      // },

      highlighted : {
          dates: [
            new Date()
          ]
      },

      sites:[],
      locations:[],
      //supervisors:[],
      trainings:[],
      weekdays:[],
      location_name:'',
      months:[
         'January', 
         'February', 
         'March', 
         'April', 
         'May', 
         'June', 
         'July ', 
         'August', 
         'September', 
         'October', 
         'November', 
         'December'
      ]
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form
           },
           api: '/api/update-periodic-task'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'periodic-tasks' })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    allLocations(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-locations'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.locations = data;
                this.location_list = data.map(el => el.name);
                return this.locations;
            }
        });
    },
    /*allSupervisor(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-supervisors'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.form.supervisor =  data.length > 0 ? data[0]._id : '';
                
                this.supervisors = data;
                return this.supervisors;
            }
        });
    },*/
    trainingData(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/all-trainings'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.trainings = data;
                return this.trainings;
            }
        });
    },
    frequencyTypes(){
      return this.$store.dispatch(POST_API, {
           data:{
             
           },
           api: '/api/all-periodic-frequencies'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.frequencies = data;
                return this.frequencies;
            }
        });
    },
    weekDaysData(){
      return this.$store.dispatch(GET_API, {
           data:{
             
           },
           api: '/api/week-days'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.weekdays = data;
                return this.weekdays;
            }
        });
    },
    resetData(){
      this.form.locations = [];
      this.form.shift = '';
      //this.form.supervisor = '';
      this.form.group = [];
      this.form.show_group = 'no';
      this.group_array = [];
      this.shift_array = [];


      this.allLocations();
      //this.allSupervisor();

      if (this.form.site != '') {
        this.siteDetail();
      }
    },
    siteDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.form.site
           },
           api: '/api/site-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                if (data.group_filter == 'yes') {
                  
                  this.form.show_group = 'yes';
                  this.taskGroupSuggestion();

                }

                var arr = [];

                data.shifts.forEach(function(item){
                  arr.push(item.shift);
                })

                this.shift_array = arr;

                
            }
        });
    },
    taskGroupSuggestion(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/site-group-suggestions'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.group_array  = this.$store.getters.getResults.data;
                return this.group_array;
            }
        });
    },
    updateFrequencies(){

      if (this.form.frequency == '') {


        //this.form.frequency = '';
        //this.form.title = '';
        this.form.site = '';
        this.form.locations = [];
        //supervisor = '';
        this.form.training = '';
        //this.form.shift = '';
        //this.form.description = '';
        //this.form.dateRange.startDate =  '';
        //this.form.dateRange.endDate = '';
        this.form.start_day_of_week = '';
        this.form.duration = '';
        this.form.week_of_month = '';
        this.form.day_of_week = '';
        this.form.month = '';
        this.form.interval = '';
        this.form.week_or_month = '';
      

      }else{

        this.form.month = '';
        this.form.week_or_month = '';
        this.form.interval = '';
       
        if (this.form.frequency != 'Weekly' && this.form.frequency != 'Fortnightly') {
            this.form.start_day_of_week = '';
            this.form.duration = '';
            this.form.week_of_month = '';
        }

        if (this.form.frequency != 'Monthly' && this.form.frequency != 'Once in two months' && this.form.frequency != 'Quarterly' && this.form.frequency != 'Half-Yearly' && this.form.frequency != 'Yearly' ) {
            
            this.form.week_of_month = '';
            this.form.day_of_week = '';
            this.form.duration = '';
        }

        this.calDuration();
      }
    },

    resetDuration(){
      this.form.start_day_of_week = '';
      this.form.week_of_month = '';
      this.form.day_of_week = '';
    },

    taskDetail(){
      return this.$store.dispatch(POST_API, {
           data:{
             id:this.$route.params.id
           },
           api: '/api/periodic-task-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.form.frequency = data.frequency;

                this.form.title = data.title;

                this.form.dateRange.startDate = data.from;
                this.form.dateRange.endDate = data.to;
                this.form.nfc_code = data.nfc_code;
                
                this.form.site = data.site;
                this.resetData();
                this.calDuration();
                this.form.locations = data.locations;
                this.form.training = data.training != null ? data.training :'';
                this.form.month = data.start_month;  

                if (this.form.frequency == 'Weekly' || this.form.frequency == 'Fortnightly' || (this.form.frequency == 'Custom' && data.week_or_month == 'week')) {
                    this.form.start_day_of_week = data.start_day_of_week; 
                    this.form.duration =  data.duration;
                    this.form.week_of_month = data.week_of_month;
                }

                if (this.form.frequency == 'Monthly' || this.form.frequency == 'Once in two months' || this.form.frequency == 'Quarterly' || this.form.frequency == 'Half-Yearly' || this.form.frequency == 'Yearly'|| (this.form.frequency == 'Custom' && data.week_or_month == 'month')) {
                    this.form.week_of_month = data.week_of_month;
                    this.form.day_of_week = data.day_of_week; 
                    this.form.duration =  data.duration;
                }

                
                this.form.description = data.description;
                this.form.id = data._id;  
                this.form.interval = data.interval;
                this.form.week_or_month = data.week_or_month;
                this.form.shift = data.shift;

                this.form.group = data.group;
                this.form.startTime = data.startTime;
                this.form.endTime = data.endTime;
                this.form.start_modify = data.start_modify;
                this.form.end_modify = data.end_modify;
                this.form.plannedStartTime = data.plannedStartTime;
                this.form.planned_time_modify = data.planned_time_modify;

                if (data.group.length > 0) {
                  this.form.show_group = 'yes';
                }
                

                //this.minDate = moment(data.from).subtract(1, 'days').format('YYYY-MM-DD');     
                return this.form;
            }
        });
    },
    calDuration(){

      if (this.form.frequency == 'Weekly') {
          this.wno = [];

          for (var i = 1; i <= 7 ; i++) {
            this.wno.push(i);
          }
      }else if (this.form.frequency == 'Fortnightly') {
          this.wno = [];
          
          for (var i = 1; i <= 14 ; i++) {
            this.wno.push(i);
          }
      }else if(this.form.frequency == 'Monthly'){
          this.mno = ['Full month'];
          for (var i = 1; i <= 30 ; i++) {

            this.mno.push(i);
          }
      }else if(this.form.frequency == 'Once in two months'){
          this.mno = ['Full month'];
          for (var i = 1; i <= 60 ; i++) {

            this.mno.push(i);
          }
      }else if(this.form.frequency == 'Quarterly'){
          this.mno = ['Full month'];
          for (var i = 1; i <= 90 ; i++) {

            this.mno.push(i);
          }
      }else if(this.form.frequency == 'Half-Yearly'){
          this.mno = ['Full month'];
          for (var i = 1; i <= 180 ; i++) {

            this.mno.push(i);
          }
      }else if(this.form.frequency == 'Yearly'){
          this.mno = ['Full month'];
          for (var i = 1; i <= 360 ; i++) {

            this.mno.push(i);
          }
      }
    },
    /*calday(){
      for (var i = 1; i <= 7 ; i++) {
        this.wno.push(i);
      }
      this.mno = ['Full month'];
      for (var i = 1; i <= 30 ; i++) {
        this.mno.push(i);
      }
    },*/
    resetModal(){
      // this.form.locations = [];
      this.location_name = '';
      this.popshowDismissibleAlert = false;
    },
    resetGroup(){
      // this.form.group = [];
      this.group_name = '';
      this.popshowDismissibleAlert = false;
    },
    openModel(){
      // this.form.locations = [];
      this.$refs['add-location'].show();
    },
    openGroupModel(){
      // this.form.group = [];
      this.$refs['add-group'].show();
    },
    addLocation(bvModalEvt){
      bvModalEvt.preventDefault()
      
      return this.$store.dispatch(POST_API, {
           data:{
             location_name:this.location_name,
             site:this.form.site
           },
           api: '/api/add-other-location'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                this.allLocations();
                this.$refs['add-location'].hide();
            }
        });
    },
    addGroup(e){
      e.preventDefault();
      
      var data = {
        
          group_name:this.group_name,
          site:this.form.site,
          set_id:null,
          multiple_start:'no',
      }

      return this.$store.dispatch(POST_API, {
           data:{
             items:data
           },
           api: '/api/add-group'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.popshowDismissibleAlert = true;
            } else {
                this.popshowDismissibleAlert = false;
                this.taskGroupSuggestion();
                this.$refs['add-group'].hide();
            }
        });
    },
    customFormatter(date) {
      return moment(date).format('DD MMM YYYY');
    },
    updateCustomDay(){

      this.form.duration = '';

      if (this.form.week_or_month != '' && this.form.interval != '') {

        var total = 0; 
        if (this.form.week_or_month == 'week') {
          total = this.form.interval * 7;

          this.wno = [];

          for (var i = 1; i <= total ; i++) {
            this.wno.push(i);
          }

        }else{
          total = this.form.interval * 30;

          this.mno = ['Full month'];
          for (var i = 1; i <= total ; i++) {

            this.mno.push(i);
          }

        }
        
        

      }else{
        this.wno = [];
        this.mno = [];
      }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'Schedule Work'
      },{
        to:{name:'periodic-tasks'},
        text: 'Schedule Task',
      },{
        to:null,
        text: 'Edit Schedule Task',
        active:true
      }];
      return item;
    }
    

  },
  mounted(){
    //this.calday();
    this.frequencyTypes();
    this.allSites();
    this.trainingData();
    this.weekDaysData();
    this.taskDetail();    
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
